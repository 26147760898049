export const REGISTER = 'REGISTER'
export const REGISTER_ERROR = 'REGISTER_ERROR'
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'
export const MERGE_ACCOUNT = 'MERGE_ACCOUNT'
export const ADD_ACCOUNT = 'ADD_ACCOUNT'
export const ADD_NEW_CARD = 'ADD_NEW_CARD'
export const ADD_NEW_CARD_ERROR = 'ADD_NEW_CARD_ERROR'
export const CHANGE_PAYMENT_METHOD = 'CHANGE_PAYMENT_METHOD'
export const CHANGE_PAYMENT_METHOD_ERROR = 'CHANGE_PAYMENT_METHOD_ERROR'
export const UPDATE_PRIMARY_CARD = 'UPDATE_PRIMARY_CARD'
export const UPDATE_PRIMARY_CARD_ERROR = 'UPDATE_PRIMARY_CARD_ERROR'
export const REMOVE_CARD = 'REMOVE_CARD'
export const REMOVE_CARD_ERROR = 'REMOVE_CARD_ERROR'
export const ERROR_WHILE_FETCHING_CARD_DETAILS = 'ERROR_WHILE_FETCHING_CARD_DETAILS'
export const UPDATE_USER_DETAILS_IN_WEBSITE_ERROR = 'UPDATE_USER_DETAILS_IN_WEBSITE_ERROR'
export const LOGIN_MERGE = 'LOGIN_MERGE'
export const UPDATE_EMAIL = 'UPDATE_EMAIL'
export const UPDATE_EMAIL_ERROR = 'UPDATE_EMAIL_ERROR'
export const MERGE_ACCOUNT_FROM_RESET_PASSWORD = 'MERGE_ACCOUNT_FROM_RESET_PASSWORD'
export const GET_USER_DETAIL_ERROR = 'GET_USER_DETAIL_ERROR'
export const UPDATE_BLOCK_STATUS = 'UPDATE_BLOCK_STATUS'
export const UPDATE_BLOCK_STATUS_ERROR = 'UPDATE_BLOCK_STATUS_ERROR'
export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR'
export const BLOCK_UNIVERSALLY = 'BLOCK_UNIVERSALLY'
export const BLOCK_UNIVERSALLY_ERROR = 'BLOCK_UNIVERSALLY_ERROR'
